import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
// import Arrow from '../../assets/Arrow'
import btnExplore from '../../assets/images/btn-explore.png'
import { device, sizes } from '../../assets/Styles'
import SEO from '../SEO'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import Breadcrumb from '../shared/Breadcrumb'
import Footer from '../shared/Footer'
import animations from './animations'

const P = styled.p`
  margin-top: 1rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* For the black button not to create the scroll bar. */
  overflow-x: hidden;

  .padding-0 {
    padding: 0;
  }
  .text-align-left {
    text-align: left;
    @media ${device.tablet} {
      text-align: center;
    }
  }

  .text-align-right {
    text-align: right;
    @media ${device.tablet} {
      text-align: center;
    }
  }
`

const Wrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @media ${device.tablet} {
    width: 100%;
    padding-top: 3rem;
  }
`

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  justify-content: center;
  @media ${device.tablet} {
    flex: 0;
  }
`

export const ImageColumn = styled(Column)`
  opacity: 1;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  padding: 4rem;

  .interior-background {
    /* height: 100%; */
  }
  img {
    cursor: pointer;
    height: auto;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '500px')};
    width: 100%;
    :last-of-type {
      right: 0;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    justify-content: flex-end;
    order: 1;
    flex: 1;
  }
`
const TextColumn = styled(Column)`
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  @media ${device.tablet} {
    padding-right: 0;
    order: 2;
    flex: 1;
    justify-content: unset;
  }
`

const Section = styled.section`
  display: flex;
  padding: 0 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: center;
  h1 {
    font-size: 180%;
    margin: 0;
  }
`

const ExploreButton = styled.div`
  background: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 211px;
  height: 208px;
  display: block;
  position: absolute;

  margin-left: -105px;
  color: #e4e4e4;
  font-size: 23px;
  line-height: 1.05em;
  text-align: center;
  &:hover div:first-child {
    transform: scale(1.1);
  }
`
const MenuButton = styled(ExploreButton)`
  bottom: 15%;
  right: 50%;
`

const AddressButton = styled(ExploreButton)`
  bottom: 45%;
  right: 20%;
`

const Background = styled.div`
  transition-property: all;
  transition-duration: 0.3s;
  width: 100%;
  height: 100%;
  background-image: url(${btnExplore});
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`

const Text = styled.span`
  transition: all 0.2s ease;
  font-family: 'Superclarendon';
  word-spacing: -0.1rem;
  padding: 30% 0;
  display: inline-block;
  font-size: 1.2em;
  margin: 0;
  line-height: 1.2em;
  position: relative;
  letter-spacing: 1px;
`

function Restaurant(props) {
  const intl = useIntl()

  const containerRef = useRef(null)
  const btnAddressBgdRef = useRef(null)
  const btnMenuBgdRef = useRef(null)
  const imgRef = useRef(null)

  const history = useHistory()
  const { windowSize } = props

  const handleClick = (path) => {
    const targets = {
      buttonRef: null,
      containerRef: containerRef.current,
    }
    switch (path) {
      case 'restaurant/location':
        targets.buttonRef = btnAddressBgdRef.current
        animations
          .scaleButtonUp(targets)
          .add(() => history.push(`/${intl.locale}/${path}`))
          .set(targets.buttonRef, {
            zIndex: 1,
            scale: 1,
          })
        break
      case 'restaurant/menu/appetizers':
        targets.buttonRef = btnMenuBgdRef.current
        animations
          .scaleButtonUp(targets)
          .add(() => history.push(`/${intl.locale}/${path}`))
          .set(targets.buttonRef, {
            zIndex: 1,
            scale: 1,
          })
        break
      default:
    }
  }
  const routes = [
    { title: 'home.title', path: '' },
    { title: 'restaurant.title', path: 'restaurant' },
  ]
  return (
    <Wrapper>
      <Container ref={containerRef}>
        <SEO title="restaurant.title" />

        {windowSize.width > sizes.phone && (
          <div className="breadcrump-wrapper">
            <Breadcrumb routes={routes} />
          </div>
        )}
        <React.Fragment>
          {windowSize.width > sizes.phone && (
            <Section>
              <TextColumn>
                <AddressButton
                  onClick={() => handleClick('restaurant/location')}
                >
                  <Background ref={btnAddressBgdRef} />
                  <Text>
                    <FormattedMessage id="restaurant.contact">
                      {(message) => message}
                    </FormattedMessage>
                  </Text>
                </AddressButton>
                <MenuButton
                  onClick={() => handleClick('restaurant/menu/appetizers')}
                >
                  <Background ref={btnMenuBgdRef} />
                  <Text>
                    <FormattedMessage id="restaurant.menu">
                      {(message) => message}
                    </FormattedMessage>
                  </Text>
                </MenuButton>
              </TextColumn>
              <ImageColumn>
                <BlurImageLoader name="gramophone_m42rbx" />
              </ImageColumn>
              {/* <Arrow /> */}
            </Section>
          )}

          <Section>
            <TextColumn alignItems="center">
              <Content className="text-align-left">
                <h1>
                  <FormattedMessage id="restaurant.ourVision.title">
                    {(message) => message}
                  </FormattedMessage>
                </h1>
                <P>
                  <FormattedMessage id="restaurant.ourVision.p">
                    {(message) => message}
                  </FormattedMessage>
                </P>
              </Content>
            </TextColumn>
            <ImageColumn
              className="padding-0"
              ref={imgRef}
              alignItems="center"
              maxWidth="700px"
            >
              <BlurImageLoader
                className="interior-background"
                name="interior_ef2waa"
              />
            </ImageColumn>
          </Section>
          <Section>
            <ImageColumn ref={imgRef} alignItems="center" maxWidth="350px">
              <BlurImageLoader name="pepper-onion_dqwg91" />
            </ImageColumn>
            <TextColumn alignItems="flex-start">
              <Content className="text-align-right">
                <h1>
                  <FormattedMessage id="restaurant.ourFood.title">
                    {(message) => message}
                  </FormattedMessage>
                </h1>
                <P>
                  <FormattedMessage id="restaurant.ourFood.p">
                    {(message) => message}
                  </FormattedMessage>
                </P>
              </Content>
            </TextColumn>
          </Section>
        </React.Fragment>
      </Container>
      <Footer />
    </Wrapper>
  )
}

Restaurant.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(Restaurant)
